<template>
	<MhView :isLoading="isLoading">
		
		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>		
		
		<div class="pagePost border border--bottom" :style="wrapperElmStyles" :key="$route.fullPath" v-if="post">
			
			<div class="border border--bottom row" v-if="$root.isSmallDevice">
				<div class="viewTitle vSpace vSpace--default hSpace hSpace--big col">
					<h2 v-html="$root.getTranslatedAcfValue(post.acf, 'postTitle')"></h2>
				</div>
			</div>
						
			<div class="pagePost__growRow row">				
				<div class="pagePost__text col col--6of12 col--sm-12of12" :class="{'border border--right' : !$root.isSmallDevice}">
					<div class="hSpace hSpace--big vSpace vSpace--pageContent">
						<div class="pagePost__maxWidthWrapper" v-html="$root.getTranslatedAcfValue(post.acf, 'text')"></div>
						<div class="pagePost__luhLogo" v-if="$root.getTranslatedAcfValue(post.acf, 'hasLuhLogo')"></div>			
					</div>
				</div>
				<template v-if="$root.isSmallDevice">
					<MhImage class="col col--6of12 col--sm-12of12" :imageObject="post.acf.image" mode="img"></MhImage>			
				</template>			
				<template v-else>
					<MhImage class="col col--6of12 col--sm-12of12" :imageObject="post.acf.image" mode="cover"></MhImage>			
				</template>	
			</div>

		</div>
		
	</MhView>
</template>

<script>
	// @ is an alias to /src
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhView from '@/components/MhView/MhView.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import { EventBus } from '@/event-bus.js'
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'pageView',
		components: {
			MhImage,			
			MhView,
			MobilePageTitle,			
		},
		mixins: [ RestHandler ],
		data() {
			return {
				isLoading: true,
				post: null,
				imageSrc: null,
				//appHeaderHeight : 0 // wird via EventBus.$on('appHeaderResized') gesetzt
			}
		},
		watch: {
			'$route.params.pageSlug' ( to, from ){
				let pageSlug = to
				this.getPage(pageSlug)
			}
		},
		computed: {
			wrapperElmStyles() {
				//const appHeaderHeight = this.appHeaderHeight
				const appHeaderHeight = this.$root.appHeaderHeight
				const elmHeight = 'calc(100vh - ' + appHeaderHeight + 'px + 1px)'
				
				if( !this.$root.isSmallDevice ){
					return {
						transition : 'all 0.1s linear',
						height     : elmHeight,
						maxHeight  : elmHeight,
					}
				}else{
					return {}
				}

			},
		},
		methods: {},
		mounted() {
			
			// fetch page
			this.restHandler__fetch({
				action : 'GET',	
				route : '/wp-json/wp/v2/pages',
				params : {
					slug : this.$route.params.pageSlug
				},
				callback : (response) => {
					this.post = response.data[0]
					this.isLoading = false				
				},
			})
			
			// post container ist zusammen mit der header height 100vh
			// wenn sich die header höhe ändert, wird die neue height 
			// hier via event empfangen und dient der berechnung der 
			// höhe des post containers
			/*
			EventBus.$on('appHeaderResized', elmHeight => {
				this.appHeaderHeight = elmHeight				
				//console.log( elmHeight )
			})			
			*/
			
		},		
	}
</script>

<style lang="less">
	.pagePost {		
		position: relative;
		//top: 0; left: 0; right: 0; bottom: 0;
		height: 100%;
		overflow: hidden;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}	
	.pagePost__growRow {
		flex-grow: 1;
		max-height: 100%; // ff needs this to prevent overflow		
	}
	.pagePost__text {
		flex-grow: 1;
		overflow: auto;
		max-height: inherit;;
	}
	.pagePost__maxWidthWrapper  {
		* {
			max-width: 910px;
			//color: red;		
		}
	}
	.pagePost__luhLogo {
		margin-top: 2.4em;
		height: 3.1em;
		background-size: contain;
		background-position: left top;
		background-repeat: no-repeat;			
		background-image: url('@/../../assets/imgs/luh-logo.svg');
	}
</style>
